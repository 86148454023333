
import {defineComponent, PropType} from 'vue'
import {useButtonCustom} from '@/core/hooks/use-button-custom';
import {useDateTime} from '@/core/hooks/use-date-time-hook';
import {IBienBanGiaiTheResponseBase} from '@/core/interfaces/ApiResponses';

import ButtonCustom from '../buttons/ButtonCustom.vue';
import WordBreakColumn from '../work-break-column/WordBreakColumn.vue';

export default defineComponent({
  name: 'bien-ban-giai-the-table',

  components: {ButtonCustom, WordBreakColumn},

  emits: ['on-check-click', 'on-detail-click', 'on-edit-click'],

  props: {
    items: {
      type: Array as PropType<Array<IBienBanGiaiTheResponseBase>>,
      default: () => ([]),
    },
    isKeHoachColumnDisplaying: {
      type: Boolean,
      default: true,
    },
    isHoanThanhButtonDisplayed: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      bienBanItems: this.items as IBienBanGiaiTheResponseBase[],
      toaXeHasDmtx(toaxe) {
        return toaxe.danh_muc_toa_xe?.length > 0;
      }
    }
  },

  watch: {
    items(value) {
      this.bienBanItems = value;
    },
  },

  setup() {
    const {moment} = useDateTime();
    const {ButtonTypeColors, ButtonsType} = useButtonCustom()

    return {
      moment,
      ButtonTypeColors, ButtonsType
    }
  },
})
